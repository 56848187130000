"use client";

import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2'
import Swal from "sweetalert2";
import "react-phone-input-2/lib/bootstrap.css";
import '../FormConfirmationDiscount/FormConfirmationDiscount.css'
import API_URL from '../API/index'

const FormConfirmationDiscount = () => {
    const {control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
      defaultValues: {
        selectedCities: [],
        trialSelectedCities: []
      }
    });
    const [btnLoading, setBtnLoading] = useState(false);
    const [voucherValid, setVoucherValid] = useState(false);
  
    const selectedPrice = watch('price');
    const selectedPriceTrial = watch('price_trial');
    const selectedPricePlatinum = watch('price_platinum');
    const selectedCities = watch('selectedCities') || [];
    const selectedTrialCities = watch('trialSelectedCities') || [];
    const voucherInput = watch('voucherCode');

  useEffect(() => {
    if (selectedPrice === "500") {
      setValue('selectedCities', ['Bandung'], { shouldValidate: true });
    } else if (selectedPrice === "6000") {
      const allCities = ["Medan", "Tangerang", "Yogyakarta", "Surabaya", "Denpasar", "Makassar"];
      setValue('selectedCities', allCities, { shouldValidate: true });
    } else if (selectedPrice === "1250") {
      setValue('selectedCities', [], { shouldValidate: true });
    }
  }, [selectedPrice, setValue]);

  useEffect(() => {
    if (selectedPriceTrial === "4200") {
      const allTrialCities = ["Medan", "Tangerang", "Yogyakarta", "Surabaya", "Denpasar", "Makassar"];
      setValue('trialSelectedCities', allTrialCities, { shouldValidate: true });
    } else if (selectedPriceTrial === "1000") {
      setValue('trialSelectedCities', [], { shouldValidate: true });
    }
  }, [selectedPriceTrial, setValue]);

  const calculateTotalPrice = () => {
    let total = 0;
    let originalTotal = 0;

    if (selectedPrice === "6000") {
      total += 6000;
      originalTotal += 6000;
    } else if (selectedPrice === "1250") {
      const cityCount = selectedCities.length;
      total += cityCount * 1250;
      originalTotal += cityCount * 1250;
    } else if (selectedPrice === "500") {
      total += 500;
      originalTotal += 500;
    }
    
    if (selectedPriceTrial === "4200") {
      total += 4200;
      originalTotal += 4200;
    } else if (selectedPriceTrial === "1000") {
      const cityCount = selectedTrialCities.length;
      total += cityCount * 1000;
      originalTotal += cityCount * 1000;
    }

    if (selectedPricePlatinum === "13500") {
      total += 13500;
      originalTotal += 13500;
    }

    const discountedTotal = voucherValid ? total * 0.5 : total;

    return { 
      discountedTotal: discountedTotal,
      originalTotal: originalTotal 
    };
  };

  const validateVoucher = () => {
    const validVoucherCode = 'NIECWEF50';
    setVoucherValid(voucherInput?.toUpperCase() === validVoucherCode);
    
    if (voucherInput?.toUpperCase() === validVoucherCode) {
      Swal.fire({
        icon: 'success',
        title: 'Voucher Applied!',
        text: 'You get a 50% discount!'
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Voucher',
        text: 'Please enter a valid voucher code.'
      });
    }
  };

  const generatePriceDetails = () => {
    const details = [];
    
    if (selectedPrice === "6000") {
      details.push("- Regular Package: AUD 6000 / 6 city");
    } else if (selectedPrice === "1250") {
      details.push(`- Regular Package: AUD ${selectedCities.length * 1250} (${selectedCities.join(", ")})`);
    } else if (selectedPrice === "500") {
      details.push("- Regular Package: AUD 500 / Bandung");
    }

    if (selectedPriceTrial === "4200") {
      details.push("- Trial Package: AUD 4200 / 6 city");
    } else if (selectedPriceTrial === "1000") {
      details.push(`- Trial Package: AUD ${selectedTrialCities.length * 1000} (${selectedTrialCities.join(", ")})`);
    }

    if (selectedPricePlatinum === "13500") {
      details.push("- Platinum Partner: AUD 13500");
    }

    if (voucherValid) {
      details.push("- 50% Discount Applied");
    }

    return details;
  };
  
  const { discountedTotal, originalTotal } = calculateTotalPrice();
  const priceDetails = generatePriceDetails();
  
  const onSubmit = async (data) => {
    setBtnLoading(true);
      
    try {
      const formData = {
        ...data,
        selectedCities: data.selectedCities || [],
        trialSelectedCities: data.trialSelectedCities || [],
        totalPrice: discountedTotal,
        originalTotal: originalTotal,
        priceDetails: priceDetails,
        voucherCode: data.voucherCode || '',
        voucherApplied: voucherValid,
        codeVoucher: voucherValid ? voucherInput.toUpperCase() : null
      };
      console.log("Form Data:", formData);

      await API_URL.eventUrl.post('form-confirmation-edufest', formData);
  
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful',
        text: 'Your form has been submitted!'
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Submission error:", error);
      
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: 'This email has already been used for registration. Please use a different email address.'
      });
    } finally {
      setBtnLoading(false);
    }
  };
  
    return (
      <>
        <div style={{maxWidth: '630px'}} className="container">
        <div className="title-form">
            <h2>Confirmation of Participant</h2>
            <h2>NIEC WORLD EDUCATION <br />FESTIVAL 2025</h2>
        </div>
          <div className="Row" id="daftar">
            <div className="Col-lg-12">
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="py-2">
                    <input 
                      {...register("fullName", { required: "Full Name is required" })} 
                      type="text" 
                      className={`form-control ${errors.fullName ? "is-invalid" : ""}`} 
                      placeholder="Full Name" 
                    />
                    {errors.fullName && <div className="invalid-feedback">{errors.fullName.message}</div>}
                </div>

                <div className="py-2">
                    <input 
                      {...register("institution", { required: "Institution Name is required" })} 
                      type="text" 
                      className={`form-control ${errors.institution ? "is-invalid" : ""}`} 
                      placeholder="Name of Institution" 
                    />
                    {errors.institution && <div className="invalid-feedback">{errors.institution.message}</div>}
                </div>

                <div className="py-2">
                    <input 
                      {...register("officialRepresentative", { required: "Official Representative Name is required" })} 
                      type="text" 
                      className={`form-control ${errors.officialRepresentative ? "is-invalid" : ""}`} 
                      placeholder="Name of Official Representative" 
                    />
                    {errors.officialRepresentative && <div className="invalid-feedback">{errors.officialRepresentative.message}</div>}
                </div>

                <div className="py-2">
                  <input 
                    {...register("email", { 
                      required: "Email is required", 
                      pattern: { 
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 
                        message: "Invalid email address" 
                      } 
                    })} 
                    type="text" 
                    className={`form-control ${errors.email ? "is-invalid" : ""}`} 
                    placeholder="Email Address" 
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>

                <div className="py-2">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: 'Phone Number is required' }}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneInput
                        {...field}
                        inputExtraProps={{
                          ref,
                          required: true,
                        }}
                        country={"id"}
                        countryCodeEditable={false}
                        inputClass={`form-control ${errors.phone && 'is-invalid'}`}
                        inputStyle={{width:'100%', height: '45px'}}
                      />
                    )}
                  />
                  {errors.phone?.message && (<div className="text-invalids">{errors.phone.message}</div>) }
                </div>
                
                <div className="bg-light p-3 mt-2 package-section">
                    <p><strong>REGULAR PACKAGE</strong> <br /><i> (Medan, Tangerang, Yogyakarta, Surabaya, Denpasar, Makassar) </i></p>
                   
                    <div className="package-pricing">
                        <div>
                            <span>OFFLINE</span>
                            <div>
                                <label>
                                    <input {...register("price")} type="radio" value="6000" /> AUD 6000 / 6 city <br />
                                    <input {...register("price")} type="radio" value="1250" /> AUD 1250 / city <br />
                                    <input {...register("price")} type="radio" value="500" /> AUD 500 / Bandung
                                </label>
                            </div>
                        </div>
                        {selectedPrice !== "500" && selectedPrice !== "6000" && (
                            <div className="city">
                                <span>Choose the name of the city:</span>
                                <div className="city-selection">
                                    <div className="city-column">
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Medan" /> Medan
                                        </label>
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Tangerang" /> Tangerang
                                        </label>
                                    </div>
                                    <div className="city-column">
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Yogyakarta" /> Yogyakarta
                                        </label>
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Surabaya" /> Surabaya
                                        </label>
                                    </div>
                                    <div className="city-column">
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Denpasar" /> Denpasar
                                        </label>
                                        <label>
                                          <input {...register("selectedCities")} type="checkbox" value="Makassar" /> Makassar
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-light p-3 mt-2">
                    <p><strong>TRIAL PACKAGE</strong> <br /><i> (Medan, Tangerang, Yogyakarta, Surabaya, Denpasar, Makassar) </i></p>
                    
                    <div className="package-pricing">
                        <div>
                        <span>OFFLINE</span>
                        <div>
                            <label>
                                <input {...register("price_trial")} type="radio" value="4200" /> AUD 4200 / 6 city <br />
                                <input {...register("price_trial")} type="radio" value="1000" /> AUD 1000 / city <br />
                            </label>
                        </div>
                        </div>
                        {selectedPriceTrial !== "4200" && (
                            <div className="city">
                            <span>Choose the name of the city :</span>
                            <div className="city-selection">
                                <div className="city-column">
                                    <label>
                                        <input {...register("trialSelectedCities")} type="checkbox" value="Medan" /> Medan
                                    </label>
                                    <label>
                                        <input {...register("trialSelectedCities")} type="checkbox" value="Tangerang"/> Tangerang
                                    </label>
                                </div>
                                <div className="city-column">
                                  <label>
                                    <input {...register("trialSelectedCities")} type="checkbox" value="Yogyakarta" /> Yogyakarta
                                  </label>
                                  <label>
                                    <input {...register("trialSelectedCities")} type="checkbox" value="Surabaya" /> Surabaya 
                                  </label>
                                </div>

                                <div className="city-column">
                                  <label>
                                    <input {...register("trialSelectedCities")} type="checkbox" value="Denpasar" /> Denpasar
                                  </label>
                                  <label>
                                    <input {...register("trialSelectedCities")} type="checkbox" value="Makassar" /> Makassar
                                  </label>
                                </div>     
                            </div>
                        </div>
                        )}
                        
                    </div>
                </div>

                <div className="bg-light p-3 mt-2">
                    <div className="d-flex">
                        <div>
                            <p><strong>PLATINUM PARTNER</strong></p>
                            <div>
                                <label>
                                    <input {...register("price_platinum")} type="radio" value="13500"/> AUD 13500<br />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-light p-3 mt-2 rounded">
                    <div className="d-flex flex-column">
                        <p><strong>SCHEDULE</strong></p>
                        <div className="d-flex justify-content-between">
                            <label className="d-flex align-items-center">
                                <input {...register("schedule")} type="checkbox" value="JUN 2025" style={{ marginRight: '8px' }} /> JUN 2025
                            </label>
                            <label className="d-flex align-items-center">
                                <input {...register("schedule")} type="checkbox" value="OCT 2025" style={{ marginRight: '8px' }} /> OCT 2025
                            </label>
                            <label className="d-flex align-items-center">
                                <input {...register("schedule")} type="checkbox" value="FEB 2026" style={{ marginRight: '8px' }} /> FEB 2026
                            </label>
                        </div>
                    </div>
                </div>

                <div className="bg-light p-3 mt-2 rounded">
                    <div className="d-flex flex-column">
                    <p><strong>TOTAL PRICE</strong></p>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                        {voucherValid && (
                            <h3 className="d-inline-block mr-3" style={{
                            textDecoration: 'line-through', 
                            color: 'gray', 
                            marginRight: '1rem'
                            }}> 
                            AUD {originalTotal.toLocaleString()}
                            </h3>
                        )}
                        <h3 className="d-inline-block text-danger">AUD {discountedTotal.toLocaleString()}</h3>
                        </div>
                        <div className="text-muted">
                        <p><strong>Order Details:</strong></p>
                        {priceDetails.map((detail, index) => (
                            <p key={index} className="mb-1">{detail}</p>
                        ))}
                        </div>
                    </div>
                    </div>
                </div>

                <div className="py-2">
                    <div className="d-flex">
                        <input 
                            {...register("voucherCode")}
                            type="text" 
                            className="form-control mr-2"
                            placeholder="Enter Voucher Code" 
                        />
                        <br />
                        <button 
                            type="button" 
                            className="btn-danger"
                            onClick={validateVoucher}
                        >
                            Apply Voucher
                        </button>
                    </div>
                </div>


                <div className="py-2">
                    <label>
                        <input {...register("confirm", { required: "You must confirm joining the festival" })} type="checkbox" value="yes" style={{ marginRight: '8px', marginTop:'20px' }} />We Confirm to Join at Education Festival 2025
                    </label>
                    {errors.confirm && <div className="text-danger">{errors.confirm.message}</div>}
                </div>
  
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn3 btn-danger btn-lg button1" disabled={btnLoading}>
                    {btnLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
            <div className="mb-5">
                <p>Participant fee must be transferred to: <br />
                <strong>OCBC NISP, BRANCH TEUKU UMAR DENPASAR BALI</strong>
                </p>
                <p>Account Name: CV Naresy Indonesia <br />
                Account Number :160800014310 <br />
                Swift Code: NISPIDJA <br />
                Branch Code: 07160 <br />
                Bank Code: 028</p>
            </div>
          </div>
        </div>
      </>
    );
}

export default FormConfirmationDiscount