import React, { useState, useEffect } from 'react';
import Logo from '../Assets/logo.png';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import '../Navbaar/navbar.css';
import { FaCalendar, FaCompass } from 'react-icons/fa';

const NavbarComponent = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='banner2'>
      <Navbar expand="lg" className={`sticky-navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
        <Container className='p-2'>
          <Navbar.Brand href="#home" className='logo'>
            <img src={Logo} alt="logo" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto hide">
              <Nav.Link className='pe-3 text-white' href="https://edufest.niecindonesia.com/#beranda" target='blank'>Beranda</Nav.Link>
              <Nav.Link className='pe-3 text-white' href="https://edufest.niecindonesia.com/#wef"  target='blank'>Apa itu WEF?</Nav.Link>
              <Nav.Link className='pe-3 text-white' href="https://edufest.niecindonesia.com/#jadwal"  target='blank'>Jadwal</Nav.Link>
              <Button href="https://edufest.niecindonesia.com/</Nav>#daftar" type='button' className='btn btn-danger btn-md'>Daftar Sekarang</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
