"use client";

import React, { useState, useEffect } from "react";
import '../CountDown/CountDown.css'

const Countdown = () => {
  const endDate = "July 05, 2025 23:59:00"; // Tanggal target

  const calculateTimeLeft = () => {
    const endDateTime = new Date(endDate).getTime();
    const now = Date.now(); // Menggunakan waktu saat ini
    const timeRemaining = endDateTime - now;

    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [countdown, setCountdown] = useState(calculateTimeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return (
    <div className="countdown-area">
      <div className="container">
        <div className="event-countdown">
          <div id="timer">
            <div id="days">
              {countdown.days} <span>Days</span>
            </div>
            <div id="hours">
              {countdown.hours} <span>Hours</span>
            </div>
            <div id="minutes">
              {countdown.minutes} <span>Minutes</span>
            </div>
            <div id="seconds">
              {countdown.seconds} <span>Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;

