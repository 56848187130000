import { Routes, Route } from "react-router-dom";
import App from './App';
import Attendance from './Attendance/Attendance.jsx';
import Ambassador from './Ambassador/Ambassador.jsx';
import FormConfirmation from "./FormConfirmation/Index.jsx";
import FormConfirmationDiscount from "./FormConfirmationDiscount/Index.jsx";


const RouteEdufest = () => {
    return (
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/attendance' element={<Attendance />} />
                <Route path='/ambassador' element={<Ambassador />} />
                <Route path="/form-confirmation" element={<FormConfirmation />} />
                <Route path="/form-confirmation-event" element={<FormConfirmationDiscount />} />
            </Routes>
    )
}

export default RouteEdufest