import React, { useState, useEffect } from 'react';
import Logo from '../Assets/logo.png';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import '../Navbar/navbar.css';
import { FaCalendar, FaCompass } from 'react-icons/fa';

const NavbarComponent = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='banner'>
      <Navbar expand="lg" className={`sticky-navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
        <Container className='p-2'>
          <Navbar.Brand href="#home" className='logo'>
            <img src={Logo} alt="logo" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto hide">
              <Nav.Link className='pe-3 text-white' href="#beranda">Beranda</Nav.Link>
              <Nav.Link className='pe-3 text-white' href="#wef">Apa itu WEF?</Nav.Link>
              <Nav.Link className='pe-3 text-white' href="#jadwal">Jadwal</Nav.Link>
              <Button href="#daftar" type='button' className='btn btn-danger btn-md'>Daftar Sekarang</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container-fluid">
        <div className="main-banner-content banner-content-center">
          <p>
            Datang & Konsultasi <span>Gratis</span>
          </p>
          <h1>
            Festival Pendidikan <span>Kuliah Luar Negeri</span> <br /> <b>2</b>
            <b>0</b>
            <b>2</b>
            <b>5</b>
          </h1>

          <ul>
            <li>
              <FaCalendar /> Medan, Jakarta, Yogyakarta, Surabaya, Makassar, & Denpasar
            </li>
            <li>
              <FaCompass /> 26 Juni - 05 Juli 2025
            </li>
          </ul>

          <div className="button-box">
            <Button href="#daftar" type='button' className='btn btn-danger btn-md'>Daftar Sekarang</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
