import React from "react";
import '../Speaking/speaking.css'

// Image imports for local use
import Speaker1 from '../Assets/speakers1.jpg';
import Speaker2 from '../Assets/speakers2.jpg';
import Speaker3 from '../Assets/speakers3.jpg';
import Speaker4 from '../Assets/speakers4.jpg';
import Speaker5 from '../Assets/speakers5.jpg';
import Speaker6 from '../Assets/speakers6.jpg';
import Speaker7 from '../Assets/speakers6.jpg';

// Data array
const SpeakersMemberData = [
  {
    image: Speaker1,
    name: "Nadiyah",
    designation: "Business Development of NIEC Medan",
  },
  {
    image: Speaker3,
    name: "Afkari Rahmadiani",
    designation: "English Teacher of NIEC Yogyakarta",
  },
  {
    image: Speaker4,
    name: "Nasaruddin",
    designation: "Country Director of NIEC INDONESIA",
  },
  {
    image: Speaker5,
    name: "Ardiansyah",
    designation: "Branch Manager of NIEC Makassar",
  },
  {
    image: Speaker6,
    name: "Leny Savitri",
    designation: "Corporate Human Resource of NIEC INDONESIA",
  },
];

const Speakers = () => {
  return (
    <div className="speakers-area ptb-120 pb-0">
      <div className="container">
        <div className="section-title">
          <span>Listen to the Event Speakers</span>
          <h2>Who's Speaking</h2>
          <div className="bar"></div>
          <div className="bg-title">Speakers</div>
        </div>
      </div>

      <div className="row m-0" style={{justifyContent: 'center'}}>
        {SpeakersMemberData.slice(0, 8).map((speaker, index) => (
          <div className="col-lg-2 col-sm-6 p-0" key={index}>
            <div className="elkevent-single-speakers">
              <img
                src={speaker.image}
                alt="speaker"
                width={200}
                height={350}
              />

              <div className="speakers-content">
                <h3>{speaker.name}</h3>
                <span>{speaker.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Speakers;
